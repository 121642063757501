﻿angular.module('taric.widgets', [])
    // Controllers
    // Components
    .component('feedbackButton', require('./components/feedback-button'))
    .component('goodscodeScroller', require('./components/goodscode-scroller'))
    .component('loadingIndicator', require('./components/loading-indicator/loading-indicator-block'))
    .component('loadingIndicatorInline', require('./components/loading-indicator/loading-indicator-inline'))
    .component('loadingIndicatorSmall', require('./components/loading-indicator/loading-indicator-small'))
    .component('modalPrintButton', require('./components/modal-print-button'))
    .component('navbarLanguageDropdown', require('./components/navbar-language-dropdown'))
    .component('printButton', require('./components/print-button'))
    .component('recentCodeButton', require('./components/recent-code-button'))
    .component('referenceCountryPicker', require('./components/reference-country-picker'))
    .component('taricNavbar', require('./components/navbar'))
    .component('taricStatus', require('./components/taric-status'))
    .component('translatedText', require('./components/translated-text'))
    // Filters
    .filter('addHyperlinks', require('./filters/add-hyperlinks'))
    .filter('countryTypeahead', require('./filters/country-typeahead-filter'))
    .filter('formatDescription', require('./filters/format-description'))
    .filter('formatDuty', require('./filters/format-duty'))
    .filter('formatGnCode', require('./filters/format-gn-code'))
    .filter('formatTaricCode', require('./filters/format-taric-code'))
    .filter('formatEztCode', require('./filters/format-ezt-code'))
    .filter('hasAdditionalCode', require('./filters/has-additional-code'))
    .filter('hasCountry', require('./filters/has-country'))
    .filter('taricCodeToChapterNumber', require('./filters/taric-code-to-chapter-number'))
    .filter('highlight', require('./filters/highlight'))
    .filter('trust', require('./filters/trust'))
    .filter('stripTaricCode', require('./filters/strip-taric-code'))
    .filter('taricDashes', require('./filters/taric-dashes'))
    .filter('toCurrencySign', require('./filters/to-currency-sign'))
    .filter('trim', require('./filters/trim'))
    .filter('toFixed', require('./filters/to-fixed'))
    // Modals
    .factory('areYouSureModal', require('./modals/are-you-sure'))
    .factory('loggingOutModal', require('./modals/logging-out'))
    .factory('widgetModals', require('./modals/modals'))
    // Services
    .factory('taricStatusService', require('./services/taric-status'))
    ;

﻿module.exports = {
    errorMessages: {
        ErrorEvaluatingCondition: 'Error evaluating measure condition'
    },
    measureConditions: {
        Action: 'Action',
        Amount: 'Amount',
        Condition: 'Condition', // As in measure condition - conditions that must be met for a measure to be applicable and/or import/export to be allowed
        Description: 'Description',
        DutyRate: 'Duty Rate',
        noAction: 'none/positive',
        noCertificate: 'none'
    },
    measureSeries: {
        AdditionalDuties: 'Additional Duties',
        AntiDumpingMeasures: 'Anti-Dumping Measures',
        Duties: 'Duties',
        NationalTaxes: 'National Taxes',
        OtherMeasures: 'Other Measures',
        Prohibitions: 'Prohibitions',
        ReferencePrices: 'Reference Prices',
        Restitutions: 'Restitutions',
        RestrictionsAndControls: 'Restrictions and Controls',
        SupplementaryAmounts: 'Supplementary Amounts',
        SupplementaryUnit: 'Supplementary Unit',
        VatRates: 'VAT Rates',
    },
    referenceCountries: {
        BE: 'Belgium',
        CH: 'Switzerland',
        DE: 'Germany',
        EU: 'European Union',
        GB: 'Great-Britain',
        IT: 'Italy',
        NL: 'The Netherlands'
    },
    tradeMovements: {
        All: 'All',
        Export: 'Export',
        Import: 'Import'
    },
    unitTypes: {
        Area: 'Area',
        Energy: 'Energy',
        GrossWeight: 'Gross Weight',
        Items: 'Number of Items',
        Length: 'Length',
        NetWeight: 'Net Weight',
        Pairs: 'Number of Pairs',
        Power: 'Power', // As in electrical power
        Volume: 'Volume',
        Weight: 'Weight'
    },
    AddCertificateCode: 'Add Certificate Code',
    AdditionalCode: 'Additional Code',
    AdditionalCodesValidationError: 'Error in additional codes',
    AdditionalCodeTypeaheadPlaceholder: 'Search by additional code or description',
    AdditionalCodeModalTitle: 'Select an additional code',
    AdditionalCodes: 'Additional Codes',
    AdditionalCodeTypes: 'Additional Code Types',
    NoAdditionalCode: 'No Additional Code',
    NoAdditionalCodesFound: 'No Additional Codes Found',
    AgriculturalComponents: 'Agricultural Components',
    MeursingAdditionalCodePlaceHolder: 'Search for additional code',
    AutonomousTariffSuspensionsMessage: 'Recommendation: make use of the Autonomous Tariff Suspension that applies for this commodity code',
    CalculateDuty: 'Calculate Duty',
    CalculateDutyAndTaxes: 'Calculate Duty and Taxes',
    Certificate: 'Certificate',
    CertificateCodeTypeaheadPlaceholder: 'Search by certificate code or description',
    CertificateCodeModalTitle: 'Select a certificate code',
    CertificateCodes: 'Certificate Codes',
    CertificateCodeTypes: 'Certificate Code Types',
    CheckMeasureConditions: 'Check Measure Conditions', // check as in verify if all of the conditions have been met
    consumptionTaxWarning: 'Consumption Tax (Verbruiksbelasting) may apply, in which case a National Additional Code is required. Below you can search for National Additional Codes.',
    Countries: 'Countries',
    CountryOfDestination: 'Country of Destination',
    CountryOfOrigin: 'Country of Origin',
    CountryOrRegion: 'Country or Region',
    CustomsValue: 'Customs Value',
    Declaration: 'Declaration', // As in 'Customs Declaration'
    DeclarationCountry: 'Declaration Country', // As in 'Country where the customs declaration is done'
    DeclarationDate: 'Declaration Date', // As in 'Date of customs declaration'
    Destination: 'Destination', // As in 'Destination country'
    DutyCalculator: 'Duty Calculator',
    DutyCalculationErrors: {
        DutyMeasureNotUniqueForPreferenceCode: 'The duty measure is not unique for the preference code. Select duty measure with or without end-use.',
        Header: 'Duty Calculation Error',
        InvalidOrMissingQuantity: 'Invalid or missing quantity',
        UnsupportedDutyExpression: 'One of the measures for this duty calculation contains an unsupported duty expression.'
    },
    DutyCalculationResult: 'Duty Calculation Result',
    DutyMeasureType: 'Duty Measure Type',
    EuLegislation: 'EU Legislation',
    excl: 'excluding', // As in 'measure is valid for all countries excl. country1, country2...'
    Excises: 'Excises',
    ExportMeasures: 'Export Measures',
    exportRefundNomenclature: 'Export Refund Nomenclature',
    Footnote: 'Footnote',
    Footnotes: 'Footnotes',
    FootnoteTypes: 'Footnote Types',
    GeographicalArea: 'Geographical Area',
    GeographicalAreas: 'Geographical Areas',
    ImportMeasures: 'Import Measures',
    InformationForOrderNumber: 'Information for Order Number', // Order Number of Tariff Quota
    Legislation: 'Legislation',
    LegislationFirstLetter: 'L',
    Measure: 'Measure',
    Measures: 'Measures',
    MeasuresForExportFrom: 'Measures for export from',
    MeasuresForImportInto: 'Measures for import into',
    MeasuresValidOn: 'Measures valid on',
    MeasureTypeTypeaheadPlaceholder: 'Select measure type',
    MeasureTypeModalTitle: 'Select measure type',
    NationalAdditionalCode: 'National additional code',
    noCertificatesFound: 'No certificate codes found',
    noFootnotesFound: 'No Footnotes Found',
    noMeasuresFound: 'No Measures Found',
    noResultsForCommodityCodesInPeriod: 'No Results for the chosen commodity codes in the chosen period',
    OptionalNationalAdditionalCode: 'Optional national additional code',
    Origin: 'Origin', // As in 'Country of Origin'
    OtherChoices: 'Other Choices', // Header for print version, showing other choices the user has made to filter measures (e.g. reference date and reference country)
    PreferenceCode: 'Preference code',
    PreferenceCodeDisplayLabel: 'Preference codes display', // display preference codes that are valid for declarations under UCC or for declarations in the situation before UCC?
    PreferenceCodeDisplayLabelPreUcc: 'Pre-UCC', // UCC as in Union Customs Code
    PreferenceCodeDisplayLabelUcc: 'DMS / UCC', // UCC as in Union Customs Code
    PreferenceCodeTypeaheadPlaceholder: 'Search by preference code or description',
    PreferenceCodeModalTitle: 'Select a preference code',
    EndUseMeasureIsAllowedWithProcedureCode44: 'The end-use measure is allowed with customs procedure 44 "End-use"',
    PreferredDutyTreatment: 'Preferred Duty Treatment',
    Quantity: 'Quantity',
    QuotaOrderNumber: 'Quota Order Number',
    QuotaOrderNumberTypeaheadPlaceholder: 'Enter quota order number',
    ReferenceLists: 'Reference lists',
    Region: 'Region',
    Regions: 'Regions',
    RetailPrice: 'Retail Price',
    SearchByCodeOrDescription: 'Search by code or description',
    ShowQuotaInformation: 'Show Quota Information',
    Taxes: 'Taxes',
    Value: 'Value',
    ValidateDeclaration: 'Validate Declaration',
    VatAndExciseEtcetera: 'VAT, Excise, Storage and Consumption Tax',
    VAT: 'VAT'
};

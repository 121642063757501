module.exports = {
    controller: modalPrintButtonController,
    templateUrl: 'widgets/components/modal-print-button.html'
};

function modalPrintButtonController() {
    var vm = {
        printPage: printPage,
    };
    return vm;

    function printPage() {
        window.print();
    }
}
module.exports = {
    controller: printButtonController,
    templateUrl: 'widgets/components/print-button.html'
};

function printButtonController() {
    var vm = {
        printPage: printPage,
    };
    return vm;

    function printPage() {
        window.print();
    }
}